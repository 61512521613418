import * as React from "react"
import {useIntl} from "gatsby-plugin-intl";
import {Layout} from "../components/layout/layout";
import Seo from "../components/seo/seo";
import {SsyIntersectionObserver} from "../components/ssyIntersectionObserver/ssyIntersectionObserver";
import {RaisingBox} from "../components/raisingBox/raisingBox";
import style from "../assets/css/snowdays.module.css"
import snowdaysHeader from "../assets/images/snowdays-header.jpg"
import {SsyLink} from "../components/ssyLink/ssyLink";

const SunrisePage = (props) => {
    const intl = useIntl();
    return (
        <Layout>
            <Seo
                title={intl.formatMessage({id: "APP_META_TITLE"})}
                lang={intl.formatMessage({id: "MAIN_LANGUAGE"})}
                url={props.location.pathname}
                description={intl.formatMessage({id: "APP_META_DESCRIPTION"})}
            />

            <img className={style.img} src={snowdaysHeader} alt=""/>

            <SsyIntersectionObserver>
                <RaisingBox>
                    <section className={style.appPage}>

                        <h1 className={style.text}>Hier sehen Sie die Bilder Ihrer Kinder des Sunrise Snow Days </h1>
                        <h4>Event ID zur Hand ✋?</h4>


                        <SsyLink type="primary"
                                 label="Los geht's ⛷️"
                                 internal={false}
                                 to={`${process.env.GATSBY_DASHBOARD_URL}/${intl.formatMessage({
                                     id: "MAIN_LANGUAGE",
                                 })}/register?e=1`}></SsyLink>
                    </section>
                </RaisingBox>
            </SsyIntersectionObserver>
        </Layout>
    );
};
export default SunrisePage